<template>
    <Form @submit="handleSubmit" v-slot="{ errors }">
        <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Twilio Integration</h1>
                    <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                    <ul class="tab_sec justify-content-start">
                        <li @click="tab = 1" :class="{ active: tab === 1 }">Account</li>
                        <li @click="tab = 2" :class="{ active: tab === 2 }">Notification</li>
                    </ul>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr" v-show="tab === 1">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Account SID</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.twilio_sid }">
                                     <Field autocomplete="off" type="text" name="twilio_sid" v-model="form.twilio_sid" placeholder="Account SID" />
                                </div>
                                <ErrorMessage name="twilio_sid" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Auth Token</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.twilio_token }">
                                     <Field autocomplete="off" type="text" name="twilio_token" v-model="form.twilio_token" placeholder="Auth Token" />
                                </div>
                                <ErrorMessage name="twilio_token" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Phone Number</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.twilio_phone }">
                                    <Field autocomplete="off" type="text" name="twilio_phone" v-model="form.twilio_phone" placeholder="Twilio Number" />
                                </div>
                                <ErrorMessage name="twilio_phone" class="text-danger" />
                            </div>
                        </div>
                        <label for="connection_status" class="switch_option capsule_btn">
                            <h5>Connection Status</h5>
                            <input type="checkbox" id="connection_status" :true-value="1" :false-value="0" v-model="form.connection_status" @change="handleConnectionStatus" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div v-show="tab === 2">
                        <ul class="tab_sec mb-3">
                            <li @click="notificationTab = 1" :class="{ active: notificationTab === 1 }">New Message Alert</li>
                            <li @click="notificationTab = 2" :class="{ active: notificationTab === 2 }">Failed Message Alerts</li>
                        </ul>
                        <div v-show="notificationTab === 1">
                            <label for="new-notification" class="switch_option capsule_btn">
                                <h5>Notification</h5>
                                <input type="checkbox" id="new-notification" :true-value="1" :false-value="0" v-model="form.sms_notification_details.status" hidden>
                                <div><span></span></div>
                            </label>
                            <template v-if="form.sms_notification_details.status">
                                <h3 class="sub_heading2 mt-2">Sending Method</h3>
                                <div class="section_wpr mb-2">
                                    <div class="edit_section">
                                        <sending-method v-model="form.sms_notification_details.notification" />
                                    </div>
                                </div>
                                <div class="setting_wpr mb-4 mt-5">
                                    <ul class="tab_sec mb-3">
                                        <li @click="newSmsTab = 1" :class="{ active: newSmsTab === 1 }">Email</li>
                                        <li @click="newSmsTab = 2" :class="{ active: newSmsTab === 2 }">SMS</li>
                                    </ul>
                                    <div v-show="newSmsTab == 1">
                                        <email-component v-if="loadEditor" v-model="newEmailContent" :errors="errors" :handle-default-email="handleDefaultEmail" ref="new-email-component" />
                                    </div>
                                    <div v-show="newSmsTab == 2">
                                        <sms-component v-if="loadEditor" v-model="form.sms_notification_details.sms_message" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" ref="new-sms-component" />
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div v-show="notificationTab === 2">
                            <label for="failed-notification" class="switch_option capsule_btn">
                                <h5>Notification</h5>
                                <input type="checkbox" id="failed-notification" :true-value="1" :false-value="0" v-model="form.sms_error_notification_details.status" hidden>
                                <div><span></span></div>
                            </label>
                            <template v-if="form.sms_error_notification_details.status">
                                <h3 class="sub_heading2 mt-2">Sending Method</h3>
                                <div class="section_wpr mb-2">
                                    <div class="edit_section">
                                        <sending-method v-model="form.sms_error_notification_details.notification" />
                                    </div>
                                </div>
                                <div class="setting_wpr mb-4 mt-5">
                                    <ul class="tab_sec mb-3">
                                        <li @click="failedSmsTab = 1" :class="{ active: failedSmsTab === 1 }">Email</li>
                                        <li @click="failedSmsTab = 2" :class="{ active: failedSmsTab === 2 }">SMS</li>
                                    </ul>
                                    <div v-show="failedSmsTab == 1">
                                        <email-component v-if="loadEditor" v-model="failedEmailContent" :errors="errors" :handle-default-email="handleDefaultFailedEmail" ref="new-email-component" />
                                    </div>
                                    <div v-show="failedSmsTab == 2">
                                        <sms-component v-if="loadEditor" v-model="form.sms_error_notification_details.sms_message" :default-sms-handler="true" :handle-default-sms="handleDefaultFailedSms" ref="new-sms-component" />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="loader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Twilio Integration',

        data () {
            return {
                tab: 1,
                notificationTab: 1,
                newSmsTab: 1,
                failedSmsTab: 1,
                form: {},
                newEmailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                failedEmailContent: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                loader: false,
                loadEditor: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            user: {
                handler () {
                    const vm = this;

                    vm.resetForm();
                },
                deep: true
            },

            modelValue (value) {
                const vm = this;
                vm.loadEditor = false;

                if (value) {
                    document.body.classList.add('modal-open');
                    setTimeout(function () {
                        vm.loadEditor = true;
                    }, 2000);
                }else{
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                storeTwilioCredentials: 'authModule/storeTwilioCredentials',
                updateTwilioConnection: 'authModule/updateTwilioConnection',
            }),

            closeModal () {
                const vm = this;

                vm.tab = 1;
                vm.notificationTab = 1;
                vm.newSmsTab = 1;
                vm.failedSmsTab = 1;
                vm.resetForm();

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form.twilio_sid        = vm.user.twilio_sid;
                vm.form.twilio_token      = vm.user.twilio_token;
                vm.form.twilio_phone      = vm.user.twilio_phone;
                vm.form.connection_status = vm.user.connection_status;
                vm.form.sms_notification_details = vm.user.sms_notification_details ? JSON.parse(JSON.stringify(vm.user.sms_notification_details)) : {};
                vm.form.sms_error_notification_details = vm.user.sms_error_notification_details ? JSON.parse(JSON.stringify(vm.user.sms_error_notification_details)) : {};
                vm.form.sms_notification_details.notification = vm.form.sms_notification_details.notification ? parseInt(vm.form.sms_notification_details.notification) : 1;
                vm.form.sms_error_notification_details.notification = vm.form.sms_error_notification_details.notification ? parseInt(vm.form.sms_error_notification_details.notification) : 1;
                vm.form.sms_notification_details.status = (vm.form.sms_notification_details.status == 'on' || vm.form.sms_notification_details.status == 1) ? 1 : 0;
                vm.form.sms_error_notification_details.status = (vm.form.sms_error_notification_details.status == 'on' || vm.form.sms_error_notification_details.status == 1) ? 1 : 0;

                vm.newEmailContent = {
                    defaultHandler: true,
                    subject: vm.form.sms_notification_details.email_subject,
                    email: vm.form.sms_notification_details.email_message,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                };

                vm.failedEmailContent = {
                    defaultHandler: true,
                    subject: vm.form.sms_error_notification_details.email_subject,
                    email: vm.form.sms_error_notification_details.email_message,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                };
            },

            handleSubmit () {
                const vm = this;
                const params = this.form;

                params.sms_notification_details.email_subject         = vm.newEmailContent.subject;
                params.sms_notification_details.email_message         = vm.newEmailContent.email;
                params.sms_notification_details.is_email_logo         = vm.newEmailContent.is_email_logo;
                params.sms_notification_details.is_signature          = vm.newEmailContent.is_signature;
                params.sms_notification_details.signature_id          = vm.newEmailContent.signature_id;
                params.sms_notification_details.show_email_logo       = vm.newEmailContent.show_email_logo;
                params.sms_notification_details.email_logo            = vm.newEmailContent.email_logo;
                params.sms_error_notification_details.email_subject   = vm.failedEmailContent.subject;
                params.sms_error_notification_details.email_message   = vm.failedEmailContent.email;
                params.sms_error_notification_details.is_email_logo   = vm.failedEmailContent.is_email_logo;
                params.sms_error_notification_details.is_signature    = vm.failedEmailContent.is_signature;
                params.sms_error_notification_details.signature_id    = vm.failedEmailContent.signature_id;
                params.sms_error_notification_details.show_email_logo = vm.failedEmailContent.show_email_logo;
                params.sms_error_notification_details.email_logo      = vm.failedEmailContent.email_logo;

                vm.loader = true;

                vm.storeTwilioCredentials(params).then((result) => {
                    if (result) {
                        vm.refreshAuth();
                        vm.closeModal();
                    }

                    vm.loader = false;
                });
            },

            handleDefaultEmail () {
                const vm = this;

                vm.newEmailContent = {
                    defaultHandler: true,
                    defaultEmail: true,
                    subject: 'New message from {{first_name}} {{last_name}}!',
                    email: `<p>Hi {{company_owner}}, You have a message from {{first_name}} {{last_name}} waiting for you in your Thrive Coach message center. Click here to reply:</p>
                            <p>{{auto_login_link}}</p>
                            <p>Message Received:</p>
                            <p>{{message_body}}</p>
                            <p>Cheers,</p>
                            <p>Thrive Coach</p>`,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                };
            },

            handleDefaultSms () {
                const vm = this;

                vm.form.sms_notification_details.sms_message = 'Hi {{company_owner}}, You have a new message from {{first_name}} {{last_name}} waiting for you in your Thrive Coach message center. Click here to reply: \n\r {{auto_login_sms_link}} \n\r Message Received: \n\r {{message_body}} \n\r\n\r Cheers, \n\r Thrive Coach';
            },

            handleDefaultFailedEmail () {
                const vm = this;

                vm.failedEmailContent = {
                    defaultHandler: true,
                    defaultEmail: true,
                    subject: 'Message Delivery Failure',
                    email: `<p>Ooops! It looks like your automated SMS message to {{first_name}} {{last_name}} was not delivered.</p>
                            <p>Click here to view the message, resend, or update your contact info:</p>
                            <p>{{auto_login_link}}</p>
                            <p>Cheers,</p>
                            <p>Thrive Coach</p>`,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                };
            },

            handleDefaultFailedSms () {
                const vm = this;

                vm.form.sms_error_notification_details.sms_message = 'Ooops! It looks like your automated SMS message to {{first_name}} {{last_name}} failed to reach them. Click here to view the message, resend, or update your contact info: \n\r {{auto_login_sms_link}} \n\r Cheers, \n\r Thrive Coach';
            },

            handleConnectionStatus () {
                const vm = this;

                const params = {
                    connection_status: vm.form.connection_status,
                    twilio_sid: vm.form.twilio_sid,
                    twilio_token: vm.form.twilio_token,
                    twilio_phone: vm.form.twilio_phone,
                };

                vm.loader = true;
                vm.updateTwilioConnection(params).then((result) => {
                    if (result) {
                        vm.refreshAuth();
                        vm.closeModal();
                    }

                    vm.loader = false;
                });
            },
        },
    }
</script>
